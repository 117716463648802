import React from 'react'

export const pageData = {
  title: 'Contact Agent Image',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    { text: 'Contact Agent Image', url: '/contact-agent-image/' },
  ],
  uri: '/contact-agent-image/',
}

export const bannerData = {
  heading: {
    text: 'How Can Agent Image Help You?',
    className: 'heading-1',
  },
  subheading1: {
    text: 'THANKS FOR REACHING OUT!',
    className: 'subtitle-7',
  },
  subheading2: {
    text:
      'Our team is here to answer any questions you have about<br/> website design and digital marketing.',
    className: 'subtitle-3',
  },
}

export const ctaCardsData = [
  {
    title: 'Frequently Asked Questions',
    subtitle: 'Got questions?<br/> We’ve got answers!',
    cta: 'READ OUR FAQ',
    ctaPhone: 'Browse Our FAQ',
    imgAlt: 'FAQs and meeting',
  },
  {
    title: '24/7 Customer Support',
    subtitle:
      'We’re always ready to assist you. Get in touch to receive expert support and advice.',
    cta: 'Contact Support',
    imgAlt: 'Customer Support',
  },
]

export const socialConnectData = {
  heading: 'The latest updates from our social feeds.',
  subheading: 'Follow us on social',
  className: 'subtitle-7',
}

import React from 'react'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import ConsultationBanner from '@components/global/banner/ConsultationBanner'
import CtaCards from '@components/global/cards/CtaCards'
import ReachUsCards from '@components/global/cards/ReachUsCards'
import SocialConnectCards from '@components/global/cards/SocialConnectCards'

import {
  pageData as page,
  bannerData,
  ctaCardsData,
  socialConnectData,
} from '@src/data/ContactUs'
import useContactUsMetadata from '@hooks/contact-us-metadata'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './ContactAgentImage.module.scss'

const ContactAgentImage = () => {
  const { bannerBG, ctaCardsLeftImg, ctaCardsRightImg } = useContactUsMetadata()
  const ctaCardsImg = [ctaCardsLeftImg, ctaCardsRightImg]

  return (
    <LayoutInnerPage hasBlackTransparentNav wrapperClassName={cx.wrapper}>
      <Seo title={page.title} uri={page.uri} />
      <ConsultationBanner
        hasDarkText
        wrapperClassName={cx.banner}
        breadcrumbs={page.breadcrumbs}
        bg={extractImage(bannerBG)}
        heading={bannerData.heading.text}
        subheading1={bannerData.subheading1.text}
        subheading2={bannerData.subheading2.text}
      />
      <ReachUsCards />
      <CtaCards
        wrapperClassName={cx.ctaCards}
        items={ctaCardsData}
        imgItems={ctaCardsImg}
      />
      <SocialConnectCards
        wrapperClassName={cx.social}
        heading={socialConnectData.heading}
        subheading={socialConnectData.subheading}
      />
    </LayoutInnerPage>
  )
}

export default ContactAgentImage
